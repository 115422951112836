<template>
    <div class="row p-0">
        <div class="fourteen wide column">

            <h2 class="downloadTab">
                Liste des dossiers

                <span class="refresh-button" title="Actualiser" @click="$emit('refresh_table')">
                    <i class="sync alternate small icon"></i>
                </span>

                <span class="right floated flex items-center" @click="$router.push({name: 'folderCreate'})">
                            <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0)">
                                    <path
                                            d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                            fill="#0953AA"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <path fill="#fff" d="M0 0h20v20H0z" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Ajouter
                        </span>
                <!--<h4 class="right floated ui header">
                    <i class="filter tiny icon"></i>
                    <div class="content">
                        Afficher:
                        <div class="ui inline dropdown">
                            <div class="text">Active</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="header">Statut</div>
                                <div v-for="filter in filters" class="item" :data-text="filter.label" @click="changeFilter(filter.val)" >{{filter.label}}</div>
                            </div>
                        </div>
                    </div>
                </h4>-->
            </h2>
            <table class="ui selectable celled table" id="searchTable">
                <thead>
                <tr>
                    <th @click="sortTasks('dossier.numero')" class="filtrable-table">Réf
                        <!--<i class="angle down icon float-right"></i>-->
                        <i v-if="sort['dossier.numero']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.numero'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.propri.nom')">
                        Assuré
                        <i v-if="sort['dossier.propri.nom']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.propri.nom'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.vehicule.marque.label')">
                        VH
                        <i v-if="sort['dossier.vehicule.marque.label']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.vehicule.marque.label'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.vehicule.n_immat')">
                        Imm
                        <i v-if="sort['dossier.vehicule.n_immat']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.vehicule.n_immat'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.assurance.date_sinistre')">
                        D.sinistre
                        <i v-if="sort['dossier.assurance.date_sinistre']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.assurance.date_sinistre'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.courtier.label')">
                        Agent/courtier
                        <i v-if="sort['dossier.courtier.label']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.courtier.label'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('dossier.type_dossier')">
                        Type dossier
                        <i v-if="sort['dossier.type_dossier']" class="long arrow alternate icon float-right"
                           :class="sort['dossier.type_dossier'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th @click="sortTasks('deadline')">
                        Temps restant
                        <i v-if="sort['deadline']" class="long arrow alternate icon float-right"
                           :class="sort['deadline'] === 1 ? 'up' : 'down'"></i>
                    </th>

                    <th> Statut </th>
                    <!--<th>Actions <i class="angle down icon float-right"></i></th>-->
                </tr>
                </thead>
                <tbody>
                <tr style="cursor: pointer" v-for="task in tasks" @click="edit(task)">
                    <td class="danger-cell">{{task.dossier.numero || '--------'}}</td>
                    <td >{{task.dossier.propri ? (task.dossier.propri.nom || '------') + ' '+ (task.dossier.propri.prenom || '-------') : '--------' }}</td>
                    <td >{{(task.dossier.vehicule && task.dossier.vehicule.marque) ? task.dossier.vehicule.marque.label || '--------' : '--------'}}</td>
                    <td >{{task.dossier.vehicule ? task.dossier.vehicule.n_immat || '--------': '--------'}}</td>
                    <td >{{task.dossier.assurance.date_sinistre ? $store.getDate(task.dossier.assurance.date_sinistre, "DD/MM/YYYY") || '--------' : '--------'}}</td>
                    <td >{{task.dossier.courtier ? task.dossier.courtier.label || '--------' : '--------'}}</td>
                    <td >{{task.dossier.type_dossier ? task.dossier.type_dossier || '--------' : '--------'}}</td>

                    <td  :class="taskStatus(task).class">
                        <!--<countdown :time="deadline(task)" :interval="1000" tag="div" >
                            <template slot-scope="props">{{ props.days > 0 ? props.days+ ' jour(s),' : ''}}
                                {{ props.hours }} h, {{props.minutes }} m, {{props.seconds }} s
                            </template>
                        </countdown>-->
                        <div v-if="taskStatus(task).status === 'hors_delai'"> 0 h, 0 m, 0 s </div>
                        <custom-counter v-else :deadline="task.deadline"/>
<!--                        <span v-else> Pas encore commencé </span>-->
                    </td>

                    <td  :class="taskStatus(task).class">{{taskStatus(task).status_label}}</td>
                    <!--<td >
                        <router-link :to="{name:'folderEdit', params:{task:task, dossier_prop:task.dossier}}"><i class="ui edit icon"></i>Edit</router-link>
                    </td>-->
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import customCounter from "@/components/customCounter";
    export default {
        name: "tasks-table",
        components:{customCounter},
        props:{tasks:{type:Array}},
        data(){
            return {
                filters:[{label:'Tous', val:null} ,{label:'Active', val:'active'} ,{label:'Complete', val:'complete'}],
                sort: {'dossier.numero': 1}
            }
        },
        methods:{
            changeFilter(filter){
              //this.selected_filter=filter;
              this.$emit('status_filter_change', filter);
            },
            deadline(task){
                let deadline = task.deadline - new Date().getTime();
                return (deadline > 0) ? deadline : 0
            },
            taskStatus(task){
                let data = {};
                let now = new Date().getTime();
                if(now > task.deadline){
                    data.status_label = 'Hors delai';
                    data.class = 'danger-cell'
                    data.status = 'hors_delai'
                }
                else if(now >task.deadline_warn && now < task.deadline ){
                    data.status_label = 'En approche';
                    data.class = 'warning-cell'
                    data.status = 'en_apporoche'
                }
                else if(now < task.deadline_warn){
                    data.status_label = 'En cours';
                    data.class = ''
                    data.status = 'en_cours'
                }
                return data;
            },
            edit(task) {
                //:to="{name:'folderEdit', params:{task:task, dossier_prop:task.dossier}}"
                this.$router.push({name: 'folderEdit', params: {task: task, dossier_prop: task.dossier}});
            },
            sortTasks(field) {
                if (Object.keys(this.sort)[0] !== field) {
                    this.sort = {};
                    this.sort[field] = -1
                }

                if (this.sort[field] === 1) {
                    this.sort[field] = -1
                } else {
                    this.sort[field] = 1
                }
                this.$emit('sort_change', this.sort);
                //this.getFolders();
            },
        },
        mounted() {
            $('.ui.dropdown').dropdown()
        }
    }
</script>

<style scoped>
    .refresh-button{
        margin-left: 2px;
        float: none;
        color: blue;
    }
    .refresh-button > i {
        font-size: 21px;
    }
</style>