<template>
	<div class="ui grid m-0 p-0">
        <div class="ui grid row p-0 m-0">
			<infoSidebar />
            <div class="eleven wide column p-0">
                <navbar :show_title="false" />
              <home-stats></home-stats>
            </div>
        </div>
		<folder-search :search_prop="search_prop"></folder-search>
    </div>
</template>

<script>
	import navbar from '@/components/navbar.vue'
	import infoSidebar from '@/components/infoSidebar.vue'
	import HomeStats from '@/components/home-stats.vue'
	import FolderSearch from '@/components/folder-search.vue'
	export default {
		name: "Home",
        props:{
            search_prop: ''
        },
		data() {
			return {
			}
		},
		components: {
			navbar,infoSidebar,HomeStats,FolderSearch
		},
		computed: {
		},
		methods: {
			getData(args) {
				console.log('getData, args:', args);
			}
		}
	};
</script>
