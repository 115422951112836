<template>
      <div class="ui grid container m-0">
                    <div class="row">
                        <div class="eight wide column">
                            <h2 class="categoryType">Home</h2>
                            <h1 class="categoryTitle">{{$route.params.action_name || 'Liste des dossiers'}}</h1>
                        </div>
                        <div class="eight wide column">
                            <div class="avalibleFolderContainer">
                                <p class="avalibleFolderValue">{{stats.all}}</p>
                                <div class="avalibleFolderText">
                                    <div class="lh-1">Dossiers</div>
                                    <div class="lh-1"><b>en attente</b></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="ui grid row justify-around container">
                        <div class="four wide column statCard">
                            <div class="statContainer">
                                <div class="statInfo red">
                                    <h1 class="statValue">{{stats.hors_delai}}</h1>
                                    <p class="statText">Dossiers hors delai</p>
                                </div>
                                <div class="statIcon">
                                    <svg width="49" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.496 0C10.967 0 0 11.194 0 25s10.967 25 24.496 25C38.03 50 49 38.804 49 25 49 11.195 38.031 0 24.496 0zM35.03 31.77l-3.902 3.976s-6.173-6.758-6.634-6.758c-.454 0-6.629 6.758-6.629 6.758l-3.903-3.976s6.63-6.208 6.63-6.76c0-.562-6.63-6.771-6.63-6.771l3.903-3.986s6.226 6.763 6.629 6.763c.406 0 6.634-6.762 6.634-6.762l3.902 3.985s-6.632 6.299-6.632 6.77c0 .45 6.632 6.761 6.632 6.761z"
                                            fill="#DD0A0A" /></svg>
                                </div>
                            </div>
                        </div>
                        <div class="four wide column statCard">
                            <div class="statContainer">
                                <div class="statInfo yellow">
                                    <h1 class="statValue">{{stats.en_approche}}</h1>
                                    <p class="statText">Dossiers en approche</p>
                                </div>
                                <div class="statIcon">
                                    <svg width="52" height="52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M51.043 38.878l-18.37-33.12C31.3 3.322 28.803 1.829 26 1.829s-5.302 1.493-6.673 3.93L.957 38.879c-1.31 2.377-1.28 5.21.122 7.556a7.533 7.533 0 006.55 3.748h36.742a7.533 7.533 0 006.55-3.748c1.402-2.346 1.432-5.18.122-7.556z"
                                                fill="#FDBF00" />
                                            <path
                                                d="M50.921 46.434a7.532 7.532 0 01-6.55 3.748H26V1.828c2.803 0 5.302 1.493 6.673 3.93l18.37 33.12c1.31 2.377 1.28 5.21-.122 7.556z"
                                                fill="#FF9F00" />
                                            <path
                                                d="M21.43 10.573v22.851h9.14V10.573h-9.14zM26 36.471a4.564 4.564 0 00-4.57 4.57 4.564 4.564 0 004.57 4.57c2.53 0 4.57-2.04 4.57-4.57 0-2.529-2.04-4.57-4.57-4.57z"
                                                fill="#5A5A5A" />
                                            <path
                                                d="M30.57 41.042a4.564 4.564 0 01-4.57 4.57v-9.14c2.53 0 4.57 2.04 4.57 4.57zM26 10.573h4.57v22.851H26V10.573z"
                                                fill="#444" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <path fill="#fff" d="M0 0h52v52H0z" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="four wide column statCard">
                            <div class="statContainer">
                                <div class="statInfo">
                                    <h1 class="statValue">{{stats.encours}}</h1>
                                    <p class="statText">Dossiers encours</p>
                                </div>
                                <div class="statIcon">
                                    <svg width="58" height="47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M35.396 32.376H22.604c-.964 0-1.748.812-1.748 1.811v4.065c0 1 .784 1.812 1.748 1.812h12.792c.964 0 1.748-.813 1.748-1.812v-4.065c0-.999-.784-1.811-1.748-1.811zm-.5 5.359H23.103v-3.03h11.793v3.03z"
                                            fill="#0953AA" />
                                        <path
                                            d="M57.43 22.308l.019-.01L49.02 5.032c-.681-1.396-2.042-2.262-3.55-2.262h-1.603V1.165C43.867.52 43.364 0 42.743 0H15.257c-.621 0-1.124.521-1.124 1.165V2.77H12.53c-1.51 0-2.87.866-3.551 2.262L.55 22.298l.018.01A4.164 4.164 0 000 24.418V42.66c0 2.25 1.766 4.08 3.937 4.08h50.126c2.171 0 3.937-1.83 3.937-4.08V24.42c0-.773-.208-1.495-.57-2.111zM43.868 5.358h1.602a1.47 1.47 0 011.322.842l6.903 14.14H49v-4.351c0-.643-.503-1.165-1.124-1.165h-1.39V8.577c0-.643-.504-1.165-1.124-1.165h-1.495V5.358zm2.885 11.795v3.186h-5.931c-1.018 0-1.945.622-2.364 1.583l-1.62 3.722a.095.095 0 01-.086.058H21.25a.095.095 0 01-.087-.058l-1.62-3.722c-.417-.961-1.345-1.583-2.363-1.583h-5.931v-3.186h35.504zM16.381 2.33h25.238v5.083H16.381V2.33zm27.857 7.412v5.083H13.762V9.741h30.476zm-33.03-3.54a1.47 1.47 0 011.323-.843h1.602v2.054h-1.495c-.62 0-1.124.521-1.124 1.165v6.247h-1.39c-.621 0-1.124.521-1.124 1.165v4.35H4.306L11.21 6.2zM54.064 44.15H3.937c-.794 0-1.44-.669-1.44-1.49V24.417c0-.822.646-1.49 1.44-1.49h13.242c.037 0 .071.022.087.057l1.62 3.722c.417.962 1.345 1.583 2.363 1.583H36.75c1.018 0 1.946-.622 2.364-1.583l1.62-3.722a.095.095 0 01.086-.058h13.242c.794 0 1.439.67 1.439 1.491V42.66c0 .822-.645 1.491-1.439 1.491z"
                                            fill="#0953AA" /></svg>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
</template>

<script>
    export default {
        data(){
            return {
                stats:{
                    all:0,
                    encours:0,
                    en_approche:0,
                    hors_delai:0,
                    en_attente:0
                }
            }
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        },
        methods:{
            getStats(){
                let now = new Date().getTime();
                let selective_query = {'receiver._id': this.current_user._id}

                console.log("selective", selective_query);
                let pipeline=[
                    {$match:{type:'link_task', 'action.task_status':'active'}},
                    {
                        $facet: {
                            'all': [
                                {$match: selective_query},
                                {$count: 'total'}
                            ],
                            'encours': [
                                {$match: {...selective_query, deadline_warn: {$gt: now}}},
                                {$count: 'total'}
                            ],

                            'en_approche': [
                                {
                                    $match: {
                                        ...selective_query,
                                        deadline_warn: {$lt: now},
                                        deadline: {$gt: now}
                                    }
                                },
                                {$count: 'total'}
                            ],
                            'hors_delai': [
                                {$match: {...selective_query, deadline: {$lt: now}}},
                                {$count: 'total'}
                            ]

                        }
                    },
                    {$unwind: {"path": "$all"}},
                    {$unwind: {"path": "$encours", preserveNullAndEmptyArrays: true}},
                    {$unwind: {"path": "$hors_delai", preserveNullAndEmptyArrays: true}},
                    {$unwind: {"path": "$en_approche", preserveNullAndEmptyArrays: true}},
                    {
                        $project: {
                            "all": "$all.total",
                            "encours": "$encours.total",
                            "hors_delai": "$hors_delai.total",
                            "en_approche": "$en_approche.total"
                        }
                    },]
                this.$store.requestAggregate({collection:'documents', pipeline:pipeline}).then(result => {
                    console.log(result[0]);
                    //this.stats = result[0];
                    _.assign(this.stats, result[0])
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        mounted() {
          this.getStats()
        }
    }
</script>

<style lang="scss" scoped>

</style>