<template>
    <div class="ui grid row  m-0 centered">
            <!-- <div class="row">
                <div class="nine wide column">
                    <div class="ui big form">
                        <div class="field" style="margin-bottom: 0px">
                            <label class="homeSearchLabel">Trouver un dossier</label>
                            <div class="position-relative">
                                    <span class="position-absolute input-icon" style="cursor: pointer" @click="searchExec">
                                        <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M20.193 3.46c-4.613-4.613-12.121-4.613-16.734 0-4.612 4.614-4.612 12.12 0 16.735 4.108 4.107 10.506 4.547 15.116 1.34.097.459.319.897.676 1.254l6.718 6.718a2.498 2.498 0 003.535 0 2.496 2.496 0 000-3.535l-6.718-6.72a2.5 2.5 0 00-1.253-.674c3.209-4.611 2.769-11.008-1.34-15.118zm-2.121 14.614c-3.444 3.444-9.049 3.444-12.492 0-3.442-3.444-3.442-9.048 0-12.492 3.443-3.443 9.048-3.443 12.492 0 3.444 3.444 3.444 9.048 0 12.492z"
                                                    fill="#2F80ED" /></svg>
                                    </span>
                                <input type="text" class="homeSearchInput" name="first-name" v-model="search"
                                       @keypress.enter="searchExec" placeholder="Recherche . . .">

                            </div>

                        </div>
                        <div class="ui accordion">
                            <div class="title" style="font-size: 15px">
                                <i class="dropdown icon"></i>
                                <u>Recherche avancée</u>
                            </div>
                            <div class="content">
                                <div class="ui segment">
                                    <div class="inline fields test">
                                        <label>Dates</label>
                                        <dateMilliRange class="custom-date" v-model="dates"></dateMilliRange>
                                    </div>

                                    <button class="ui right floated button" @click="searchExec" >Chercher</button>

                                    <div style="clear: both"></div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div> -->
            <tasks-table :tasks="tasks" @refresh_table="findDossier" @status_filter_change="filterChanged"
                @sort_change="sortChanged"/>
        </div>
</template>

<script>
    import tasksTable from './tasks-table'
    import dateMilliRange from '@/components/search/dateMilliRange'
    export default {
        name: 'folder-search',
        components:{tasksTable,dateMilliRange},
        props:{
            search_prop:{
                type: String,
                default: ''
            }
        },
        data(){
            return {
                firstLoaded:true,
                tasks:[],
                search: '',
                dates: null,
                selected_status_filter:'active'
            }
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        },
        methods:{
            filterChanged(filter){
                log('filterCHanged', filter);
                this.selected_status_filter = filter
                this.findDossier();
            },
            sortChanged(sort) {
                log('sortchanged', sort)
                this.sort = sort
                this.findDossier();
            },
            findDossier(searchQuery=null){
                console.log('findDossier')
                let route_filter = this.$route.params.action_id;
                console.log(route_filter);
                let query = {type:'link_task', 'receiver._id': this.current_user._id, 'action._id': route_filter};
                let pipe_line = [
                    {$sort:{date_creation:1}},
                    {
                        $graphLookup: {
                            from: this.$SHARED.collections.documents,
                            startWith: "$id_doc",
                            connectFromField: "id_doc",
                            connectToField: "_id",
                            maxDepth: 1,
                            as: "dossier"
                        }
                    },
                    {$unwind: "$dossier"},

                ];
                if(!route_filter){
                    delete query['action._id']
                }
                if(route_filter === 'dispatcher' || (!route_filter && this.current_user.type==='dispatcher')){
                    query['receiver._id'] = {$in:[this.current_user._id, 'dispatcher']};
                }

                if(this.selected_status_filter){
                    query['action.task_status'] = this.selected_status_filter;
                }
                //search
                if(searchQuery){
                    pipe_line.push({$match: searchQuery});
                }

                let args = {
                    collection: this.$SHARED.collections.documents,
                    query: query,
                    concat_pipeline: pipe_line,
                    options: {page: 1, limit: -1}
                };

                // sort
                if (!_.isEmpty(this.sort)) {
                    args.options.sort = JSON.parse(JSON.stringify(this.sort))
                    if (Object.keys(this.sort)[0] !== 'numero') {
                        args.options.sort['numero'] = 1;
                    }
                }

                this.$store.requestFind(args).then(data => {
                    this.tasks = data.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages[code]});
                })
            },
            searchExec(){
                console.log(this.dates);
                //this.searchQuery();
                if(this.firstLoaded)
                    this.selected_status_filter = null //remove filter if search
                this.findDossier(this.searchQuery());
            },
            searchQuery() {
                console.log("searchQuery");
                if(!this.search && !this.dates)
                    return null;
                let text_query = []
                let dates_query = []
                if(this.search){
                    const text_fields= [
                        {field:'dossier.numero', caseIns:true},
                        {field:'dossier.vehicule.n_immat', caseIns:false},
                        {field: 'dossier.propri.nom', caseIns:true},
                        {field: 'dossier.propri.prenom', caseIns:true},
                        {field: 'dossier.propri.tel'},
                        {field: 'dossier.adverse.nom', caseIns:true},
                        {field: 'dossier.adverse.prenom', caseIns:true},
                        {field: 'dossier.adverse.tel', caseIns:true},
                        {field:'dossier.adverse.vehicule.n_immat', caseIns:false},
                    ];
                    text_fields.forEach(field_elm => {
                        const field_toadd={[field_elm.field]: {$regex: this.search}}
                        if(field_elm.caseIns){
                            field_toadd[field_elm.field].$options = 'i'
                        }
                        text_query.push(field_toadd);
                    })
                }
                if(this.dates){
                    const dates_fields = [
                        'dossier.date_creation',
                        'dossier.assurance.date_sinistre',
                        'dossier.assurance.date_validation',
                        'dossier.vehicule.dmc',
                        'dossier.adverse.vehicule.dmc'
                    ]
                    let field_query = {};
                    if(this.dates.start)
                        field_query.$gt = this.dates.start;
                    if(this.dates.end)
                        field_query.$lt = this.dates.end;

                    dates_fields.forEach(field_elm =>{
                        dates_query.push({[field_elm]:field_query})
                    })
                }
                let query = {}
                if(dates_query.length && text_query.length){
                    query.$and=[{$or:dates_query},{$or:text_query}]
                } else if(text_query.length){
                    query.$or = text_query;
                } else if(dates_query.length){
                    query.$or = dates_query;
                }

                console.log("QUERY", query);
                return query;
            },
        },
        updated() {
            $('.ui.accordion').accordion({exclusive:true});
        },
        mounted() {
            $('.ui.accordion').accordion({exclusive:true});
            this.search = this.search_prop;
            this.findDossier()
        }
    }
</script>

<style scoped>

</style>