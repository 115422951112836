<template>
    <VueCtkDateTimePicker locale="fr" :range="true" :label="label" :custom-shortcuts="short_cuts"
                          v-model="date" :right="right" formatted="L" :id="id"/>
</template>

<script>
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    export default {
        name: "dateMilli",
        props: {
            label: {
                type: String,
                default: ''
            },
            right:{
                type: Boolean,
                default: true
            },
            id:{
                type: String,
                default: ''
            },
            value: Object
        },
        data(){
            return{
                date:{start:null,end:null},
                short_cuts:[
                    { key: 'today', label: "Aujourd'hui", value: 'day' },
                    { key: 'thisWeek', label: 'Cette semaine', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'La semaine précédente', value: '-isoWeek' },
                    { key: 'last7Days', label: 'Les derniers 7 jours', value: 7 },
                    { key: 'last30Days', label: 'Derniers 30 jours', value: 30 },
                    { key: 'thisMonth', label: 'Ce mois-ci', value: 'month' },
                    { key: 'lastMonth', label: 'Le mois dernier', value: '-month' },
                    { key: 'thisYear', label: 'Cette année', value: 'year' },
                    { key: 'lastYear', label: 'L’année dernière', value: '-year' }
                ]
            }
        },
        components:{
            VueCtkDateTimePicker
        },
        methods:{
            convertToMillis(dateOutput){
                return dateOutput !=null ? this.$moment(dateOutput, "YYYY-MM-DD hh:mm a").valueOf(): null;
            },
            convertToDate(dateInput){
                return dateInput ? this.$moment(dateInput).format("YYYY-MM-DD hh:mm a") : null;
            }
        },
        watch:{
            date:function(chosenDate, oldVal){
                if(JSON.stringify(chosenDate) !== JSON.stringify(oldVal)){
                    let final_result = null;
                    if(!_.isEmpty(chosenDate)){
                        final_result = _.mapValues(chosenDate,(value,key)=>{
                            return this.convertToMillis(value)
                        })
                        /*if(final_result.start === final_result.end){
                            final_result.end = final_result.end + 86240000
                        }*/
                        console.log("Final_ result", final_result)
                    }
                    this.$emit('input', final_result);
                }
            },
            value:function(incomingDate){
                this.date=_.mapValues(incomingDate,(value,key)=>{
                    return this.convertToDate(value)
                })
            }
        },
        beforeMount() {
            if(this.value){
                this.date= _.mapValues(this.value,(value,key)=>{
                    return this.convertToDate(value)
                })
            }
        }

    }
</script>
