<template>
    <div>
        <div v-if="($moment().hour() >= 18 && $moment().hour() <= 23) || ($moment().hour() >= 0 && $moment().hour() < 9)">
            {{ getRestTime() }}
        </div>
        <countdown v-else :time="time()" :interval="1000" tag="div" ref="countdown">
            <template slot-scope="props">{{ props.days > 0 ? props.days+ ' jour(s),' : ''}}
                {{ props.hours }} h, {{props.minutes }} m, {{props.seconds }} s
            </template>
        </countdown>
    </div>
</template>

<script>
    export default {
        name: "customCounter",
        props:{
            deadline:{
                type: Number,
                default: 0
            }
        },
        data(){
            return {
                momentDate: {
                    hour: null,
                    minute: null
                },
                checkTimeInterval: null
            }
        },
        methods:{
            time(){
                let deadline = this.deadline - this.$moment().valueOf();
                return (deadline > 0) ? deadline : 0
            },
            getRestTime(){
                let new_deadline = this.deadline;
                let nextdate = this.$moment(new_deadline).set({"hour": 9, "minute": 0, "second": 0}).valueOf();
                let deadline =  new_deadline - nextdate;
                let days = Math.floor(deadline / (1000 * 60 * 60 * 24));
                let hours = Math.floor((deadline % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((deadline % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((deadline % (1000 * 60)) / 1000);
                days = (days > 0) ? days + 'd, ' : '';
                return days + hours + " h, " + minutes + " m, " + seconds + " s";
            },
            getCurrentTime(){
                this.checkTimeInterval = setInterval(()=>{
                    if( (this.$moment().hour() >= 18 && this.$moment().hour() <= 23) || (this.$moment().hour() >= 0 && this.$moment().hour() < 9) )
                    {
                        if(this.$refs.countdown){
                            this.$refs.countdown.abort();
                        }
                        clearInterval(this.checkTimeInterval);
                    }

                }, 1000)
            }
        },
        beforeMount() {
            this.getCurrentTime()
        }
    }
</script>

<style scoped>

</style>