<template>
    <div class="five wide column sidebar-container">
        <router-link :to="{name: 'home'}"> <img src="@/assets/images/CFE.png" alt /> </router-link>
        <div class="sidebarFolderContainer" :class="{'fixed-sidebar' : $route.name === 'folderEdit','fixed-sidebar-large' : $route.name === 'search'}">
            <div class="sidebarFolderHeader">DOSSIERS</div>

            <div class="item" v-for="action in action_list" style="margin-top: 1em">
                <router-link :to="{ name: 'home_action', params:{ action_id:action._id, action_name: action.name},  }" class="label">{{ action.name }}</router-link>

                <div class="sidebarFolderStat red">
                    <span>{{action.total_horsDelai}}</span>
                </div>
                <div class="sidebarFolderStat yellow">
                    <span>{{action.total_enApproche}}</span>
                </div>
                <div class="sidebarFolderStat">
                    <span>{{action.total_enCours}}</span>
                </div>
            </div>
            <!--<div class="sidebarFolderAction">
                <svg width="20" height="20" fill="none" class="action-icon" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20zm0-18.438c-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.786 8.438-8.438 0-4.652-3.786-8.438-8.438-8.438zM14.074 10L8.672 4.598 7.567 5.703 11.864 10l-4.297 4.297 1.105 1.105L14.074 10z"
                        fill="#FBE51F"
                    />
                </svg>
                <span>EXPERTISE AVANT</span>
            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            action_list: []
        }
    },
    methods:{
        getActionList(){
            let match = { "receiver.username": this.current_user.username, "action.task_status":  "active"};
            const now = new Date().getTime();
            const data = {
                collection: this.$SHARED.collections.documents,
                pipeline: [
                    {$match: match},
                    {$group: {
                            _id: "$action._id",
                            name: {$first: "$action.name"},
                            total_enCours: {
                                $sum: {
                                    $cond: {
                                        if: { $gt: ["$deadline", now] },
                                        then: 1,
                                        else: 0
                                    }
                                }
                            },
                            total_enApproche: {
                                $sum: {
                                    $cond: [
                                        {
                                            $and: [{$lt: ["$deadline_warn", now]}, {$gt: ["$deadline", now]}]

                                        },
                                        1,
                                        0
                                    ]
                                }
                            },
                            total_horsDelai: {
                                $sum: {
                                    $cond: {
                                        if: { $lt: ["$deadline", now] },
                                        then: 1,
                                        else: 0
                                    }
                                }
                            }
                        }
                    },
                    {$sort: {_id: 1}}
                ]
            };
            this.$store.requestAggregate(data).then(result => {
                this.action_list = result;
            }, code => {
                this.$notify({
                    group: 'user-message',
                    type: 'warn',
                    text: this.$SHARED.messages.technical_error
                });
            })
        }
    },
    computed:{
        current_user(){
            return this.$store.state.current_user;
        }
    },
    mounted() {
        this.getActionList()
    }
};
</script>

<style scoped>
    .label {
        text-transform: uppercase;
        color:white !important;
    }
    .router-link-active {
        color:yellow !important;
    }
</style>